import { faUnderline } from '@fortawesome/free-solid-svg-icons';
import React, { ReactElement } from 'react';
import './AppContainer.scss';
import { IAppProps } from "./IAppProps";

function AppContainer({
  additionalResourcesTitle,
  additonalResourcesSection,
  appLogo,
  primaryNav,
  appTitle,
  secondaryNav,
  children,
  footerNav,
  footerLogo,
  welcomeText,
  welcomeParagraph
}: IAppProps): ReactElement {

  const primaryNavElment = primaryNav ? <div className='flex-fill p-2'>{primaryNav}</div> : undefined;
  return (
    <>
      <header className="sticky-top global p-lg-2 w-100 border-bottom border-2 overflow-hidden">
        <nav className='navbar'>
          <div className='container-fluid'>
            <a className='navbar-brand' href="https://www.ntsb.gov/Pages/home.aspx">
              {appLogo}
              <a className='text-decoration-none' href="https://carol.ntsb.gov/">
              <h2 className='d-none d-lg-inline' >{appTitle}</h2>
              <h2 className='.d-none d-xs-inline d-sm-inline d-md-inline d-lg-none'>CAROL</h2>
              </a>
            </a>
            <div className="justify-content-end" id="secondaryNav">
              {secondaryNav}
            </div>
          </div>
        </nav>
      </header>
      <section className="container-fluid">
        <div className='row welcomeRow'>

          <div className='col-lg-11 offset-lg-1 col-md-12 m3-md-3'>
            <h1>{welcomeText}</h1>
            <p>{welcomeParagraph}</p>
          </div>

        </div>
      </section>
      <section className='container-md'>
        {children}
      </section>
      <div className='row aboveAdditionalResources'></div>

      <section className="container-fluid">
        <div className='row'>
          <div className='col-lg-11 offset-lg-1 col-md-12 m3-md-3'>
            <h2>{additionalResourcesTitle}</h2>
          </div>
        </div>
      </section>
      <div className='row belowAdditionalResources'></div>

      <section className='container-md'>
        {additonalResourcesSection}
      </section>
      <footer className="global justify-content-start align-top footerClass w-100 p-lg-2 w-100 border-top border-2 overflow-hidden">
        <nav className='navbar'>
          <div className='container-fluid '>
            {footerNav}
            <a href='/'>{footerLogo}</a>
          </div>
        </nav>
      </footer>

    </>
  );
}

export default AppContainer;
