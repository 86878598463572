import React from 'react';
import AdditionalResources from './AdditionalResources/additionalResources';
import AdditionalResourcesSection from './AdditionalResourcesSection';
import AppContainer from './AppContainer';
import Navigation from './Navigation/Navigation';
import settings from './settings.json';
import SplashBody from './SplashBody/SplashBody';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

export default function Home() {
  return <React.StrictMode>
    <AppContainer
      additionalResourcesTitle={settings.appSecondComponentTitleText}
      welcomeText={settings.appHomeHeaderText}
      welcomeParagraph={settings.appHomeText}
      additonalResourcesSection={
        <AdditionalResourcesSection>
          <AdditionalResources 
                  items={[
                      { title: settings.additionalResourcesItems.aviation.TitleText.toString(), content: <>{settings.additionalResourcesItems.aviation.BodyText.toString()}</>, url: settings.additionalResourcesItems.aviation.HyperLink, urlString: settings.additionalResourcesItems.aviation.HyperLinkText },
                      { title: settings.additionalResourcesItems.downloadableAviationDataset.TitleText.toString(), content: <>{settings.additionalResourcesItems.downloadableAviationDataset.BodyText.toString()}</>, listItems: settings.additionalResourcesItems.downloadableAviationDataset.ListItems, url: settings.additionalResourcesItems.downloadableAviationDataset.HyperLink, urlString: settings.additionalResourcesItems.downloadableAviationDataset.HyperLinkText },
                      { title: settings.additionalResourcesItems.ntsbData.TitleText.toString(), content: <>{settings.additionalResourcesItems.ntsbData.BodyText.toString()}</>, url: settings.additionalResourcesItems.ntsbData.HyperLink, urlString: settings.additionalResourcesItems.ntsbData.HyperLinkText },
                      { title: settings.additionalResourcesItems.ntsbResearch.TitleText.toString(), content: <>{settings.additionalResourcesItems.ntsbResearch.BodyText.toString()}</>, url: settings.additionalResourcesItems.ntsbResearch.HyperLink, urlString: settings.additionalResourcesItems.ntsbResearch.HyperLinkText },
                  ]}
                  listItems={[
                      <div><FontAwesomeIcon className='' icon={faCircle} style={{ fontSize: "0.25em", verticalAlign: "0.5em" }} />&nbsp; <a target='_blank' href='https://rosap.ntl.bts.gov/cbrowse?pid=dot%3A32931&parentId=dot%3A32931'>DOT library collection of Civil Aeronautics Board Aircraft Accident Reports from 1934 to 1965</a></div>,
                      <div><FontAwesomeIcon className='' icon={faCircle} style={{ fontSize: "0.25em", verticalAlign: "0.5em" }} />&nbsp; The FAA's <a target='_blank' href='https://www.asias.faa.gov/'>Aviation Safety Information Analysis and Sharing</a> (ASIAS) system enables users to perform integrated queries across multiple databases, search an extensive warehouse of safety data, and display pertinent elements in an array of useful formats.</div>,
                      <div><FontAwesomeIcon className='' icon={faCircle} style={{ fontSize: "0.25em", verticalAlign: "0.5em" }} />&nbsp; The <a target='_blank' href='https://www.ntsb.gov/safety/data/Pages/daily-publication-dashboard.aspx'> Daily and Pending Aviation Publication Report</a> provides users with visibilty into recently published cases within the past seven days, as well as forthcoming reports scheduled for publication.</div>
                  ]}
          />
        </AdditionalResourcesSection>
      }
      appLogo={<img src={settings.footerLogo} alt="Application Logo" />}
      appTitle={settings.appTitle}
      secondaryNav={<Navigation navItemClass='linkNavBar' navItemLinkClass='ntsb-link' items={[
        { url: settings.helpLink, content: <>{settings.helpText}</> }

      ]} />}

      footerNav={<Navigation
          items={[
            { url: settings.helpLink, content: <>{settings.helpText}</> },
            { url: settings.homeLink, content: <>{settings.homeText}</> }]}
          navItemClass='linkNavBar' 
          navItemLinkClass='ntsb-link' />}
      footerLogo={<img className="footerLogo" alt='NTSB logo' src={settings.appLogo}></img>} >
      <SplashBody />
    </AppContainer>
  </React.StrictMode>;

};