import './CenteredContainer.scss';
import React, { ReactElement } from 'react';
export interface ICenteredContainerProps {
    children: ReactElement;
}
export default function CenteredContainer({ children }: ICenteredContainerProps): ReactElement {
    return <div className='d-flex container justify-content-center mx-auto'>
        <div className='d-flex row justify-content-center mx-auto'>
            <div className='d-flex col-11 childColumn justify-content-center mx-auto'>
                {children}
            </div>
        </div>
    </div>
}