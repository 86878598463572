import React, { ReactElement } from 'react';
import { ISearchCardProps } from "./ISearchCard";
import './SearchCard.scss';

function SearchCard(props: ISearchCardProps): ReactElement {
    return (
        <div>
            {props.items.map((item, i) =>
                <div className="d-flex card justify-content-start mx-auto" key={i.toString()}>

                    <div className={item.background || item.header || "card-body" || "mx-auto" || "autoWidth"}>
                        <div className='row px-2'>
                            <div className='col-lg-1 col-md-12  cardImage mx-auto'>
                                {item.searchCardImage}
                            </div>
                            <div className='col-lg-10 col-md-12 cardBody text-start mx-auto'>
                                <h4 className={props.searchCardTitleClass}>{item.title}</h4>
                                <h6 className={props.searchCardSubtitleClass}>{item.subtitle}</h6>
                                <p className={props.searchCardMainTextClass}>{item.mainText}</p>
                                <a href={item.url} target="_blank" className={props.searchCardLinkClass || "stretched-link"}></a>
                            </div>
                            <div className='col-lg-1 col-md-12 arrowImage'>
                                {item.arrow}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default SearchCard;
