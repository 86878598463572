import Navigation from './Navigation';


function NavTest() {
    return (
      <div className="App">
        
        <Navigation items={[{url: "http://www.google.com", content: <span>content goes here</span>}]}/>
      </div>
    );
  }
  
  export default NavTest;