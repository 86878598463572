import React, { ReactElement } from 'react';
import { IAdditionalResourcesProps } from './IAdditionalResources';
import './AdditionalResources.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

function AdditionalResources(props: IAdditionalResourcesProps): ReactElement {

    return (
        <div className='row overflow-hidden gx-10'>
            {props.items.map((item, i) =>
                <a style={{ textDecoration: "none" }} key={i.toString()} className='col-lg-3 col-md-12' target='_blank' href={item.url}>
                    
                <div className='additional-resource mx-2 p-4'>
                        
                    <p className="column-title text-center">{item.title}</p>
                       
                    <p style={{ color: "black", font: "bold", fontWeight: 600 }} className={props.additionalResourceItemClass || "column-content"}>{item.content}</p>

                    <ul className={props.additionalResourceItemClass || "column-content"} style={{ color: "black", fontSize:"0.8em" }}>
                        {item.listItems?.map((lItem, lIndex) =>
                            <li id={i.toString() + "-" + lIndex.toString()}>{lItem.toString()}</li>
                        )}
                    </ul>
                      
                </div>
            </a>)}
            <div className='col-lg-12 col-md-12'>
                <div className='additional-resource m-2 pt-2'>
                    <ul className='pl-0' style={{ textAlign: 'left', marginTop: '10px', paddingLeft: '0px', listStyle: 'none' }}>
                    {props.listItems?.map((listItem, i) =>
                        <li key={i.toString()} style={{ marginLeft: "20px" }}>{listItem}</li>
                    )}
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default AdditionalResources;