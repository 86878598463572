import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchCard from '../Search/SearchCard';
import '../SplashBody/SplashBody.scss';
import { faCircleInfo, faMagnifyingGlass, faAngleRight, faPlane, faGear, faList } from '@fortawesome/free-solid-svg-icons';
import CenteredContainer from '../CenteredContainer';

export default function SplashBody() {

    return <div className='row justify-content-center'>
        <CenteredContainer>
        <SearchCard items={[
            {
                title: "Select a search type below to get started.",
                searchCardImage: <FontAwesomeIcon className='fa-2x leftAlignmentNeeded' icon={faCircleInfo} />,
                background: "blueGradient",
                textColor: "text-white",
            },
            {
                title: "Basic Investigation & Recommendation Search",
                subtitle: "Create a basic search using select fields. Includes all NTSB recommendations (1967-present) and data from all NTSB aviation investigations (1962-present) and surface mode investigations (highway, marine, railroad, pipeline, and hazmat) from 2010-present.",
                mainText: "",
                url: "https://data.ntsb.gov/carol-main-public/basic-search",
                searchCardImage: <FontAwesomeIcon className='fa-2x leftAlignmentNeeded' icon={faMagnifyingGlass} />,
                arrow: <FontAwesomeIcon className='fa-2x fas' icon={faAngleRight} />,
                header: "non-gradient"
            },
            { 
                title: "Aviation Investigation Search",
                subtitle: "Create a more specific search using aviation fields. Includes aviation investigation data from 1962-present.",
                url: "https://www.ntsb.gov/Pages/AviationQueryv2.aspx",
                searchCardImage: <FontAwesomeIcon className='fa-2x leftAlignmentNeeded' icon={faPlane} />,
                arrow: <FontAwesomeIcon className='fa-2x fas' icon={faAngleRight} />,
                header: "non-gradient" },
            { 
                title: "Custom Search Builder", 
                subtitle: "Create a custom search using a combination of investigation and/or recommendation data fields.",
                url: "https://data.ntsb.gov/carol-main-public/query-builder",
                searchCardImage: <FontAwesomeIcon className='fa-2x leftAlignmentNeeded' icon={faList} />,
                arrow: <FontAwesomeIcon className='fa-2x fas' icon={faAngleRight} />, 
                header: "non-gradient" },
            { 
                title: "Published Searches", 
                subtitle: "View published searches of recommendations or investigations covering specific issue areas. Published searches show dynamic data reflecting current information each time you open them.",
                mainText: "",
                url: "https://data.ntsb.gov/carol-main-public/published-queries", 
                searchCardImage: <FontAwesomeIcon className='fa-2x leftAlignmentNeeded' icon={faGear} />,
                arrow: <FontAwesomeIcon className='fa-2x fas' icon={faAngleRight} />, 
                header: "non-gradient" }

        ]} />
        </CenteredContainer>
        <div className='row content-left col-11' style={{ textAlign: 'left' }}>
            <div>For assistance conducting searches, contact <a href='mailto:data@ntsb.gov'>data@ntsb.gov</a>.</div>
            <div>For assistance with or questions about CAROL, contact <a href='mailto:safti@ntsb.gov'>safti@ntsb.gov</a>.</div>
        </div>
    </div>;
};