import React, { ReactElement } from 'react';
import { INavigationProps } from "./INav";
import './Navigation.scss';

function Navigation({ className,
    items,
    navItemClass,
    navItemLinkClass }: INavigationProps): ReactElement {

    return (
        <ul className={className || "navbar-nav"}>
            {items.map((item, i) =>
                <li key={i.toString()} className={navItemClass || "nav-item"}><a className={navItemLinkClass || "nav-link"} href={item.url} id={item.id} role="button" aria-expanded="false">{item.content}</a></li>)}
        </ul>
    )


}
export default Navigation;